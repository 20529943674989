<template>
  <v-app id="login-form">
    <div class="bg" />
    <v-content class="grey lighten-2">
      <v-container
        fluid
        fill-height
      >
        <v-row
          no-gutters
          align-sm="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="10"
            md="4"
          >
            <v-card class="elevation-12">
              <div class="pa-5 d-flex justify-center">
                <v-img
                  src="img/logo.svg"
                  height="40"
                  width="40"
                  contain
                />
              </div>
              <v-divider />
              <ErrorAlert />
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="email"
                    :disabled="isTokenSet"
                    label="Email"
                    name="email"
                    prepend-inner-icon="mdi-account"
                    type="text"
                    solo
                    color="primary"
                    @keypress.enter="submit"
                  />

                  <v-text-field
                    v-model="password"
                    :disabled="isTokenSet"
                    label="Password"
                    name="password"
                    prepend-inner-icon="mdi-lock"
                    solo
                    color="primary"
                    class="mb-n7"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword= !showPassword"
                    @keypress.enter="submit"
                  />
                </v-form>
                <a
                  class="d-flex justify-end my-3 mb-0 text-decoration-none"
                  :href="passwordResetLink"
                  target="_blank"
                >
                  <span>Forgot password?</span>
                </a>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  large
                  block
                  color="primary"
                  :loading="isLoading"
                  :disabled="isTokenSet"
                  @click="submit"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-center my-5 white--text">
              <span>Thingsnesia &copy;  {{ new Date().getFullYear() }}</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserLogin',
  data() {
    return {
      showPassword: false,
      email: null,
      password: null,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters(['isTokenSet']),
    passwordResetLink() {
      return `${process.env.VUE_APP_API_URL}/password/reset`;
    },
  },

  created() {
    this.$vuetify.theme.dark = false;
  },

  methods: {
    ...mapActions(['userLogin']),
    async submit() {
      this.isLoading = true;
      const { redirect } = this.$route.query;
      await this.userLogin({
        email: this.email,
        password: this.password,
        redirect,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style>
.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("https://images.unsplash.com/photo-1532601224476-15c79f2f7a51?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=20")
    no-repeat center center;
  background-size: cover;
  background-color: grey;
}

.bg::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(7px); /* apply the blur */
  backdrop-filter: blur(7px); /* apply the blur */
  pointer-events: none; /* make the pseudo class click-through */
}
</style>
